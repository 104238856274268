// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()
import "stylesheets/application"
import 'controllers'

// import {loadStripe} from '@stripe/stripe-js/pure';
// Stripe.js will not be loaded until `loadStripe` is called
// const stripe = await loadStripe('pk_test_51IKksSJ4EPvx5uNfNDTuao3OmYYTwe8q2Q0ly2g6TmtMtRmfrKxFs93wmOx6g4fHEv7P3tjXDx8Vi4RmsLVt84cJ00HrlMu3vB');
